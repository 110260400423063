body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgb(0, 0, 0);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	box-sizing: border-box;
	/* cursor: none; */
}



@font-face {
	font-family: "Monsterrat";
	src: url('./fonts/Montserrat-Regular.otf');
	font-weight: normal;
  }
  
  @font-face {
	font-family: "ProximaNova";
	src: url('./fonts/ProximaNova.otf');
  }
  
  @font-face {
	font-family: "Poppins";
	src: url("./fonts/Poppins-Regular.otf");
  }

  @font-face {
	  font-family: 'Spinwerad';
	  src: url('./fonts/spinwerad.ttf');
  }

  @font-face {
	  font-family: 'Autumn';
	  src: url('./fonts/AutumninNovember.ttf');
  }


  @font-face {
	  font-family: 'Fight';
	  src: url('./fonts/FIGHTT__.ttf');
  }

  @font-face {
	  font-family: 'Kasse';
	  src: url('./fonts/KasseFLF.ttf');
  }

  @font-face {
	  font-family: 'Aero';
	  src: url('./fonts/AeroviasBrasilNF.ttf');
  }


  @font-face {
	font-family: 'Copperplate';
	src: url('./fonts/CopperplateRegular.ttf');
}

@font-face {
	font-family: 'CopperplateN';
	src: url('./fonts/CopperplateBQ.otf');
}