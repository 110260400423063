.footer-container {
  position: absolute;
  bottom: 0;
  z-index:999;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  background-color:rgba(0, 0, 0, 0.552);
  width: 100%;
  

}

.footernav {
padding: 2rem;
font-family: Copperplate;
font-size: 0.9rem;
 }

.fnlink {
  padding: 2rem;
  bottom: 0;
}

.copyright {
  font-family: CopperplateN;
  position: relative;
  padding: 0;
  font-size: 0.8rem;
  color: white;
}


.footerlogo {
  height: 3rem;
}

/* .dev {
  color: white;
  font-size: 0.8rem;
  padding-bottom: 2rem;
  font-family: CopperplateN;
  justify-content: right;
  text-align: right;
  padding-right: 1rem;
} */

.fl3 {
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 0.7rem;
  padding-top: 0rem;
  padding: 1rem;
  font-family: CopperplateN;
}

