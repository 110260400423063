

.logobutton {
  font-size: 1.5rem;
  margin: 1.5rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  cursor: default;
}

*::-webkit-scrollbar{
  display: none;
}


.wrapper {
  position: relative;
  z-index: 3;
}


.grain {
  opacity: 70%;
}
.bgimg {
  background-image: url('https://i.gifer.com/Azgz.gif');
  background-size: fill;
  position: absolute;
  z-index: 99;
  opacity: 0.7 !important;
  width: 100%;
  height: 500vh;
  
}

.footerwrapper {
position: absolute;
bottom: 0;
margin: 0 auto;
width: 100%;
height: auto;
}


