.container {
  color: white;
  top: 0;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  
}

.hdr {
  margin: 0 auto;
  color: white;
  z-index: 999;
  display: flex;
  top: 5rem;
  position: absolute;
  height: auto;
  justify-content: center;
  
}

.slogan {
  font-family: 'CopperplateN';
  font-size: 1.1rem;
  text-align: center;
  position: absolute;
  top: 40vh;
  width: 100%;
  justify-content: center;
  align-items: Left;
  
}

.by {
  font-family: 'CopperplateN';
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  top: 43vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  
}


/* header styles  */
@media screen and (min-width: 460px) {
  .dtview {
    top: 30vh;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
    font-size: 2.8rem;
    font-weight: bolder;
    line-height: 3rem;
    font-family: "Copperplate";
    z-index: 999;
  }
  
  .artiststmnt {
    max-width: 500px;
  }
/* dt view */
  .featuredart {
    width: 100%;
    max-width: 460px;
    padding: 0 auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    
  }
  
  .featuredart2 {
    width: 100%;
    max-width: 460px;
    padding: 1rem auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    
  }

  .panel2 {
    display: flex;
    margin: 1rem auto;
    padding: 2rem;
    
    
  }
  
  .subpanel {
    max-width: 260px;
    margin: 0 auto;
    
  }

  .panel {
    
    width: 100%;
    margin: 1rem auto;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
  .featured {
    padding-bottom: 4rem;
  }
}


.mbview {
  visibility: hidden;
}

@media screen and (max-width: 460px) {
  .mbview {
    visibility: visible;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    top: 25vh;
    justify-content: center;
    width: 100%;
    z-index: 999;
    font-size: 3rem;
    font-weight: bolder;
    line-height: 3rem;
    font-family: "Copperplate";
  }
  .dtview {
    visibility: hidden;
  }
 

  .featured {
    margin-top: 13rem;
  }

  .featuredart {
    width: 100%;
    max-width: 280px;
    padding: 0 auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    
    
  }
  
  .panel {
    
    width: 100%;
    margin: 1rem auto;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
  
  .panel2 {
    
    margin: 0 auto;
    padding: 1rem;
   
    
  }
}

.arrow {
  bottom: 1rem;
  font-size: 2rem;
  position: absolute;
  right: 0;
  z-index: 999;
}

a {
  text-decoration: none;
  color: white;
  scroll-behavior: smooth;
}

/* art display  */
.featured {
  z-index: 999;
  position: absolute;
  top: 175vh;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  justify-content: center;
 text-align: center;
 
}

.biosection {
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 125vh;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.252);
}

.artiststmnt {
  align-content: center;
  font-size: 1rem;
  line-height: 1rem;
  width: 100%;
  text-align: center;
  font-family: CopperplateN;
  margin: 0 auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  
}

.stmnthdr {
  margin: 0 auto;
  width: 100%;
  font-size: 1.5rem;
  position: absolute;
  text-align: center;
  font-family: Copperplate;
  top: 119vh;
}

.fthdr {
  font-family: Copperplate;
  font-size: 1.5rem;

}

.subpanel {
  margin: 0 auto;
  padding-top: 1rem;
}

.title {
  font-family: CopperplateN;
  font-size: 1.1rem;
}