.container {
    color: white;
    top: 0;
    position: absolute;
    margin: 0 auto;
    width: 100%;
     background-image: url('./images/grain5.gif');
    background-size: cover;
    background-repeat: repeat;
    filter: url("./images/grain5.gif");
    z-index: 999;
}

.hdr {
    top: 15vh;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
    font-size: 2.8rem;
    font-weight: bolder;
    line-height: 3rem;
    font-family: Copperplate;
    z-index: 999;
}


.formwrapper {
   justify-content: center;
   width: 100%;
   margin: 0 auto;
    top: 24vh;
   position: absolute;
}

.footerwrapper {
    position: absolute;
    bottom: 0;
    background: transparent;
    z-index: 999;
    margin: 0 auto;
    width: 100%;
    }

.contactdetails {
    width: 100%;
    position: absolute;
    top: 30vh;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: rgba(0, 0, 0, 0);
}

.cicon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.clink {
    font-size: 1.2rem;
    font-family: "CopperplateN";  
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(247, 247, 247, 0.405);
    text-decoration-skip-ink: auto;
    
}

.link {
    
    justify-content: center;
    height: 100%;
    align-items: center;
    display: flex;
    margin: 0 auto;
    width: 100%;
    
}

